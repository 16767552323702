import React from 'react'

export const Footer = () => {
    return (
        <section id="footer">
            <div className="container pt-5">
                <div className="row text-center text-xs-center text-sm-left text-md-left">
                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <h5> Contacto </h5>
                        <ul className="list-unstyled">
                            <li className="text-white user-select-all"><a href="tel:+523325446331">33 25446331</a></li>
                            <li className="text-white user-select-all mt-4 ">
                                <a className="hover:text-gray-500" href="https://wa.me/<3325446>">WhatsApp</a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-xs-12 col-sm-6 col-md-6">
                        <h5>Navegación</h5>
                        <ul className="list-unstyled quick-links">
                            <li><a href="/quienes-somos">Quiénes Somos</a></li>
                            {/*<li><a href="/politicas-para-franquicias">Políticas para Franquicias</a></li>*/}
                            <li><a href={`${process.env.PUBLIC_URL}/Terminos&Condiciones.pdf`} download="Terminos&Condiciones.pdf">Términos y condiciones</a></li>
                            <li><a href={`${process.env.PUBLIC_URL}/AvisoPrivacidad.pdf`} download="AvisoPrivacidad.pdf">Aviso de Privacidad</a></li>
                            <li><a href="https://zwolf.store/producto/contabilidad-y-declaraciones-sat/">Precios de Contabilidad en línea (Sin empleados)</a></li>
                            <li><a href="https://zwolf.store/producto/contabilidad-declaraciones-sat-imss-e-impuestos-sobre-nomina/">Precios de Contabilidad en línea (Con empleados)</a></li>
                            <li><a href="https://zwolf.store/producto/facturacion/">Precios de Facturación Electrónica</a></li>
                        </ul>
                    </div>
                </div>
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-5">
                        <ul className="list-unstyled list-inline social text-center">
                            <li className="list-inline-item"><a href="https://www.facebook.com/despachozwolf"><i className="fa fa-facebook"></i></a></li>
                            <li className="list-inline-item"><a href="https://twitter.com/grupozwolf"><i className="fa fa-twitter"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.instagram.com/despachozwolf/"><i className="fa fa-instagram"></i></a></li>
                            <li className="list-inline-item"><a href="https://www.linkedin.com/in/grupozwolf?originalSubdomain=mx"><i className="fa fa-linkedin"></i></a></li>
                            <li className="list-inline-item"><a href="https://youtube.com/playlist?list=PLfSZyIf4Pv3c87nrwrtjdF8PR52i3MGd1&si=NXv65u0BhfslJzSR"><i className="fa fa-youtube"></i></a></li>
                        </ul>
                    </div>
                    <hr />
                </div>	
                <div className="row">
                    <div className="col-xs-12 col-sm-12 col-md-12 mt-2 mt-sm-2 text-center text-white">
                        <p><u><a href="https://www.grupozwolf.com/">Grupo Zwolf</a></u> SAPI de CV® </p>
                    </div>
                    <hr />
                </div>	
            </div>
	</section>
    )
}
